.landing-wrapper{
    font-family: "Manrope" !important;
    font-style: normal !important;
    font-feature-settings: 'liga' off;
    background: #FFFFFF !important;
}


.plan-lists:hover {
    background: #333252 !important;
    border-radius: 24px !important;
    color: #FFF !important;

} 

.css-e0oqbw-MuiTypography-root img {
    width: 153px;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.priceBoxes
{
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-top: -16px;
    width: calc(100% + 321px);
    margin-left: -183px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
}
.plan-lists{
    /* transition: padding 2s ease-out 100ms; */
}
.plan-lists:hover .discount {
    background: #FFFFFF !important;
    color: #8645FF !important;
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: 70 !important;
    font-size: 12px !important;
    line-height: 15px !important;
    /* padding: 5px 5px 5px 5px !important; */
    border-radius: 4px;
    margin-right: 10px;
}

.plan-lists:hover .plan-button {
    color: #FFFFFF !important;
    background: #FF6C23 !important;
    /* padding: 10px 0 10px 0 !important; */
    /* transition: padding 2s ease-out 100ms; */
    cursor: pointer;
}

.plan-lists:hover .card-actions {
    /* margin: 0 10px 10px 10px !important; */
}

.plan-lists .plan-button {
    color: #FF6C23 !important;
    background: #FFFFFF !important;
}

.plan-lists .price {
    color: rgba(0, 0, 0, 0.87) !important;
    text-transform: capitalize;
}

.plan-lists:hover .price {
    color: #FFF !important;
}
.plan-lists:hover .MuiCardHeader-title{
    color: #FF6C23 !important;
}
.plan-lists:hover .css-cveggr-MuiListItemIcon-root{
    color: white;
}

.social-media-footer svg {
    padding: 5px;
}
.slick-dots li button:before {
    color: #FF6C23;
}

.divider {
    width: 50px !important;
    height: 0px !important;
    top: 40px !important;
    opacity: 0.2 !important;
    border: 1px solid #FFFFFF !important;
    list-style: none !important;
    margin-right: 75% !important;
    margin-left: 0 !important;
}

.exp-container {
    background: #333252;
}

.slick-track>img {
    position: absolute;
    z-index: -1;
}

.slick-dots{
    bottom: 0 !important;
}
.slides img {
    margin: 0 auto;
}
ul.slick-dots li.slick-active button{
    width: 16px;
}
ul.slick-dots li.slick-active button:before{
    background: #FF6C23 !important;
    width: 16px !important;
    height: 10px !important;
}
ul.slick-dots li{
    margin: 4px !important;
    width: 10px;
    height: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
ul.slick-dots li.slick-active{
    width: 16px;
    height: 10px;
}
ul.slick-dots li button{
    padding: 0 !important;
    width: 10px;
    height: 10px;
    margin: 0 !important;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
ul.slick-dots li button:before{
    display: block;
    content: "";
    width: 10px !important;
    height: 10px !important;
    background: #D9DBE1;
    border-radius: 15px;
    opacity: .6;   
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.billBoxes .MuiGrid-item { transition: all 400ms ease;}
.billBoxes .MuiGrid-item:hover { transform: translateY(-10px);} 
.main-container{
    padding: 10px;
}

.shadowWidth { position: relative; left:12px;}
.priceBoxes { display: flex !important; align-items: initial !important;  }
.priceBoxes .MuiGrid-item { height: initial !important;}
.priceBoxes .plan-lists { height: 100%;}

ul.pricing_list li > div {padding-top: 3px; padding-bottom: 3px; }
.priceBox {margin-top: 5px; margin-bottom: 5px;}
ul.pricing_list span.MuiTypography-root {line-height: 1.1;}
.no_space{margin-top: 0;margin-bottom: 0;}


.plan-section-wrap{
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom : 30px;
}
.plan-item{
    background: #FFFFFF;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 24px;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
}
.plan-item__header{
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.plan-item__header h2{
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #333333;
    margin: 0;
}
.plan-item__body{
    padding-bottom: 8px;
}
.plan-item__body ul{
    margin: 0;
    padding: 0;
}
.plan-item__body ul li{
    padding-left: 28px;
    padding-bottom: 16px;
    position: relative;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    opacity: .3;
    list-style-type: none;
}
.plan-item__body ul li.active{
    opacity: 1;
}
.plan-item__body ul li .icon{
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    top: 0;
}
.plan-item__price{
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: rgba(51,51,51,.68);
    padding-bottom: 16px;
}
.plan-item__price span{
    font-size: 14px;
    font-weight: 700;
    color: rgba(51,51,51,.96);   
}
.plan-item__price span sub{
    font-size: 17px;
    bottom: 0;
    font-weight: 400;
    vertical-align: middle;
}
.plan-item__footer{
    margin-top: auto;
}
.plan-item__footer .plan-button{
    background: #FFF3ED;
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FF6C23;
    height: 48px;
    text-transform: capitalize;
    border-radius: 19px;
}

.plan-item:hover{
    background: #333252;
}
.plan-item:hover .plan-item__header h2{
    color: #ffffff;
}
.plan-item:hover .plan-item__body ul li{
    color: #ffffff;
}
.plan-item:hover .plan-item__price{
    color: rgba(255,255,255,.68);
}
.plan-item:hover .plan-item__price span{
    color: rgba(255,255,255,.96);
}
.plan-item:hover .plan-item__footer .plan-button{
    background: #FF6C23;
    color: #ffffff;
    border-radius: 19px !important;
}
.plan-item:hover .plan-item__header h2 .priceanimate-charcter
{
    background-color: #d32f2f !important;
} 

.how-it-work-section{
    background: #fff;
    padding: 0px 0 90px 0px;
}
.about-us-wrap{
    padding: 0px 1px 100px 1px;
}

.aboutUsList li{
    padding-bottom: 12px;
    cursor: pointer;
}
.contact-form .MuiGrid-item{
    padding-bottom: 16px;
}

.experience-item{
    padding: 60px 40px 25px 35px;
}
.experience-item h3{
    margin-top: 0;
    margin-bottom: 40px;
}

.menu-btn.MuiButton-text{
    text-transform: capitalize;
    font-family: "Manrope" !important;
    padding-left: 16px;
    padding-right: 16px;
    color: #FFFFFF;
    border-radius: 40px;
}

.slick-prev:before,
.slick-next:before{
    color:#FF6C23 !important;
}
.css-zsp9kj-MuiInputBase-root-MuiInput-root.Mui-error:after {
    border-bottom: 2px solid #d32f2f !important;
}
.MuiInput-underline:after {
    border-bottom: 2px solid #FF6C23 !important;
}
.features.MuiBox-root:hover img {
    background: #2a2653;
    border-radius: 50%;
}
@media screen and (max-width:468px) {
    .shadowWidth {
        left: -32px !important;
        top: 0px !important;
        height: 277px;
    }
}
@media screen and (min-width: 1100px) and (max-width : 1200px) {
    .exp-container {
        height: auto !important;
    }

    .features-left {
        padding-right: 5%;
    }

    .features-right {
        padding-left: 5%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1100px) {
    .exp-container {
        height: auto !important;
        padding-bottom: 10px;
    }

    .features-left {
        padding-right: 5%;
    }

    .features-right {
        padding-left: 5%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .bannerText {
        flex: 1;
        padding-left: 37px !important;
    }
}
/* @media screen and (min-width: 768px) and (max-width:1023px) {
    .footer-menu {
        padding-left: 10% !important;
    }

    .features-left {
        padding-right: 5%;
    }

    .features-right {
        padding-left: 5%;
    }
    .shadowWidth{
        top:40px;
    }
} */

/* @media screen and (max-width:768px) {
    .css-1nskafj {
        position: absolute !important;
        bottom: 8% !important;
    }
    .css-1nskafj img {
        height: auto;
        width: 150px;
    }
        .experience-grow-productivity img {
            width:300 !important
        }
} */

@media screen and (max-width:425px) {
    .css-1nskafj {
        position: absolute !important;
        bottom: 8% !important;
    }
    .aboutUsList {
        font-size: 12px !important;
    }
    .slick-dots{
        bottom:-30px !important
    }
}


@media screen and (max-width:665px) {
    .banner-section .css-1aw3c5a-MuiGrid-root>.MuiGrid-item {
        padding-top: 27px;
    }
    .bannerText .css-9g80zm-MuiTypography-root {
        font-size: 26px;
        line-height: 42px !important;
        padding-right: 0px !important;
        line-height: 30px;
        margin-top: -20% !important;
        padding-right: 0;
        text-align: center;
        padding-top: 43px !important;
    }
    .css-8atqhb
    {
    top: -22px !important;
    line-height: 44px !important;
    padding-right: 4% !important;
    padding-top: 55px !important;
    }
    .about-us-wrap {
        margin-top: -114px;
        /* height: 382px; */
    }
    button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButtonBase-root.css-14znxl9-MuiButtonBase-root-MuiButton-root {
        margin-top: 31px !important;
    }
    .shadowWidth {
        left: -3px !important;
        top: 0px !important;
    }
    .features-main.css-1csi0se {
        padding-top: 0px !important;
        padding-bottom: 3px !important;
    }
    .features.css-14nmsd9 {
        margin-bottom: 36px;
    }
    .features .css-xxr6w8 {
    margin-bottom: 4px;
    }
    .features-main.css-1csi0se
    {
        padding-top: 0px;
    }
    .banner-section {
        height: auto;
        background-size: inherit;
    }
    .bannerText{
        padding-bottom: 0;
        line-height: 7;
    }
    .WhoWeServe.css-956djh-MuiTypography-root
    {
        margin-top:-111px;
        padding-bottom: 40px  !important;
    }
    
    .experience-item {
        padding: 30px 36px 30px 32px !important;
    }
    .experience-item .MuiBox-root:last-child {
        padding-bottom: 0;
    }
    .exp-container {
        padding-bottom: 0;
    }
    .planHeading .css-17cuulv
    {
    margin-top: -31px;
    font-size: 12px;
    }
    .planHeading .css-17cuulv h2
    {
    font-size: 18px;
    line-height: 37px;
    }
    .plan-section-wrap {
        margin-top: -16px !important;
        margin-bottom: 7px;
    }
    .about-us-wrap {
        padding: 0px 20px 45px  25px;
        margin-top: -80px;
        margin-bottom: 100px;
    }
    .css-jim6v-MuiTypography-root {
        font-size: 15px;
        line-height: 26px;
        text-align: center;
    }
    .how-it-work-section {
        padding: 0px 0 37px 0px;
    }
    .footerMenu  img
    {
        padding-top: 0px !important;
    }
    .footerSubtitle p {
        font-size: 11px;
    }
    .footerLogo img {
       width :50%;
       /* margin-top: -38px; */
    }
    /* .SocialIcons img {
        width: 97px !important;
    } */
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
        background: transparent;
        box-shadow: none;
    }
    .MuiBox-root.css-1hskriy {
        background: #fff;  
    }
    .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.priceBoxes {
        box-sizing: border-box;
        display: flex;
        -webkit-box-flex-wrap: wrap;
        flex-wrap: wrap;
        width: 100%;
        flex-direction: row;
        margin-top: 1px;
        width: calc(100% +  -49px);
        margin-left: 19px;
        justify-content: center; 
    }
    
    
}

@media screen and (max-width:899px) {
    .exp-container {
        height: auto !important;
        /* padding-bottom: 10px; */
    }
    .features {
        align-items: center !important;
        justify-content: center !important;
        text-align: center !important;
    } 
}

@media screen and (min-width:601px) and (max-width:1024px) {
    .slick-slider.slick-initialized {
        padding-left: 5%;
    }
    .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.priceBoxes {
        box-sizing: border-box;
        display: flex;
        -webkit-box-flex-wrap: wrap;
        flex-wrap: wrap;
        width: 100%;
        flex-direction: row;
        margin-top: -16px;
        width: calc(100% + 310px);
        margin-left: -166px;
        justify-content: center;
    }
}
@media screen and  (min-width:820px) and (max-width:1180px) {
   
    .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.priceBoxes {
        box-sizing: border-box;
        display: flex;
        -webkit-box-flex-wrap: wrap;
        flex-wrap: wrap;
        width: 100%;
        flex-direction: row;
        margin-top: -16px;
        width: calc(100% + 172px);
        margin-left: -92px;
        justify-content: center;
    }
    .css-9g80zm-MuiTypography-root {
        font-size: 46px;
        line-height: 59px !important;
        padding-right: 17% !important;
        padding-top: 29px !important;
    }
    main.MuiContainer-root.MuiContainer-maxWidthLg.MuiContainer-fixed.banner-section.css-i12lbg-MuiContainer-root
    {
        height: 622px;
    }
    /* .shadowWidth {
        left: 476px;
        top: -285px;
    } */
    .WhoWeServe.css-956djh-MuiTypography-root {
        padding-top: 10px;
    }
    .features-main.css-17cuulv {
        padding-top: 17px;
    }
    .about-us-wrap {
        padding: 0px 2px 69px 12px;
        /* margin-top: -72px  !important; */
    }
    .features-main.css-1ss2ei0-MuiContainer-root
    {
        padding-top: 1px !important;
        padding-bottom: 0px !important;
    }
    .about-us-wrap.css-1aw3c5a-MuiGrid-root {
        width: calc(100% + 24px);
        margin-left: 22px !important;
    }
    .features-left {
        padding-right: 0;
        padding-left: 5%;
    }
    .features p {
        font-size: 16px;
    }
    .features-main {
        padding: 10px 0px 30px 0px;
    }

    .experience-item {
        padding: 60px 40px 60px 35px;
    }
    .experience-item .MuiBox-root:last-child {
        padding-bottom: 0;
    }
    .exp-container {
        padding-bottom: 0;
    }
    .about-us-wrap p {
        text-align: center;

    }
    .how-it-work-section {
        padding: 0px 0 40px 0px;
    }
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
        background: transparent;
        box-shadow: none;
    }
    .MuiBox-root.css-1hskriy {
        background: #fff;
    }
    /* .shadowWidth {
    
        left: 487px;
    } */
    .plan-section-wrap {
        margin-top: -38px;
    }
    .SocialIcons.css-e0oqbw-MuiTypography-root img {
        width: 137px;
    }
    
}
.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 36px;
}
.priceanimate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #f3691a 0%,
    #107a33 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 36px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.line {
    width: 100%;
    height: 4rem;
    overflow: hidden;
    border: 1px solid black;
    padding: 0;
    margin-bottom: 16px;
  }
  
  /* flipping class and key frames*/
  .flipX {
    animation: 1s anim-flipX ease 2s;
  }
  @keyframes anim-flipX {
    0% {
      opacity: 0;
      transform: rotateX(90def);
    }
    50% {
      opacity: 1;
      transform: rotateX(720deg);
    }
    100% {
      /* animate nothing to pause animation at the end */
      opacity: 1;
      transform: rotateX(720deg);
    }
  }
  
  /* lineup class and keyframes */
  .lineUp {
    animation: 10s anim-lineUp ease-out 1s;
  }
  @keyframes anim-lineUp {
    0% {
      opacity: 0;
      transform: translateY(80%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  /* flipY class and keyframes*/
  .flipY {
    animation-name: spin, depth;
    animation-timing-function: linear;
   /* animation-iteration-count: 10s; */
    animation-duration: 1s;
    animation-delay: 2s;

  }
  @keyframes spin {
    0% {
      transform: rotateY(0deg);
    }
    100% {
      transform: rotateY(-360deg);
    }
  }
  @keyframes depth {
    0% {
      text-shadow: 0 0 black;
    }
    25% {
      text-shadow: 1px 0 black, 2px 0 black, 3px 0 black, 4px 0 black, 5px 0 black;
    }
    50% {
      text-shadow: 0 0 black;
    }
    75% {
      text-shadow: -1px 0 black, -2px 0 black, -3px 0 black, -4px 0 black,
        -5px 0 black;
    }
    100% {
      text-shadow: 0 0 black;
    }
  }
  
  /* subtle zoom to attention and then back */
  .pop-outin {
    animation: 2s anim-popoutin ease infinite;
  }
  
  @keyframes anim-popoutin {
    0% {
      color: black;
      transform: scale(0);
      opacity: 0;
      text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
    25% {
      color: red;
      transform: scale(2);
      opacity: 1;
      text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
    }
    50% {
      color: black;
      transform: scale(1);
      opacity: 1;
      text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      /* animate nothing to add pause at the end of animation */
      transform: scale(1);
      opacity: 1;
      text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
    }
  }
  
  .fromtop {
    animation: 2s anim-fromtop linear infinite;
  }
  @keyframes anim-fromtop {
    0% { opacity: 0; transform: translateY(-100%);}
    25% { opacity: 1; transform: translateY(0%);}
    50% {
    }
    100% {
    }
  }
  .top-to-btm {
    position: relative;
  }
  .icon-position {
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 20;
  }
  .icon-style {
    background-color: #FF6C23;
    border: 2px solid #fff;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    color: #fff;
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all 0.5s ease-in-out;
  }
  .icon-style:hover {
    animation: none;
    background: #fff;
    color: #FF6C23;
    border: 2px solid #FF6C23;
  }
  @keyframes movebtn {
    0% {
      transform: translateY(0px);
    }
    25% {
      transform: translateY(20px);
    }
    50% {
      transform: translateY(0px);
    }
    75% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  