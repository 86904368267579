*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-track {
  background: #b1b1b1;
  border-radius: 8px;
}
*::-webkit-scrollbar-thumb {
  background-color: #919191;
  border-radius: 8px;
}
.slider-image-delete-btn {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 0, 0, 0.7);
  color: white;
  padding: 0.25rem 0.5rem;
  border: none;
  cursor: pointer;
}

.slider-image-delete-btn:hover {
  background-color: rgba(255, 0, 0, 1);
}

.mutlipremium-image {
  width: 400px;
  height: auto;
  margin: 1rem 0.75rem;
}

.mainpremium-image {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  height: 300px;
}
.editpremium-image {
  width: 400px;
  height: auto;
}

/* add-button styling */
.add-cancel-button {
  font-size: 12px !important;
  font-weight: 550 !important;
  background-color: #333252 !important;
}

.add-cancel-button:hover {
  font-size: 12px !important;
  font-weight: 550 !important;
  background: linear-gradient(
    to left,
    rgb(197, 43, 113),
    rgb(206, 58, 94),
    rgb(216, 74, 74),
    rgb(227, 93, 50)
  ) !important;
}

.ps-name.label + .MuiInput-formControl {
  margin-top: 0px !important;
}
.header-tab-btn {
  background: linear-gradient(
    to left,
    rgb(197, 43, 113),
    rgb(206, 58, 94),
    rgb(216, 74, 74),
    rgb(227, 93, 50)
  );
  color: white;
  font-weight: 540 !important;
  font-size: 14px !important;
}

.table-icon-color {
  color: #333252;
}
